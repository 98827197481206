.banners-list-v3 {
  padding: 60px 0;

  &-list {
    align-items: center;
    display: flex;
    height: 166px;
    justify-content: space-between;

    .slick {
      &-slide {
        margin: 0 12px;

        @media #{$md-min-width} {
          margin: 0 40px;
        }
      }
    }
  }

  &-item {
    align-items: center;
    -webkit-backface-visibility: hidden;
    background-color: $whiteColor100;
    border: 1px solid transparent;
    border-radius: $borderRadius;
    color: $secondaryColor80;
    display: flex;
    height: 100%;
    justify-content: center;
    min-height: 166px;
    @include h100();
    @include transition(box-shadow, background-color, color, border-color);

    &:is(a, button) {
      &:hover,
      &:focus {
        border-color: $grayColor100;
        outline: none;
        text-decoration: none;
      }
    }

    &:first-child {
      -webkit-backface-visibility: visible;
      display: flex;
    }
  }

  &-image {
    border-radius: $borderRadius;
    display: block;
    height: 100%;
    min-height: 166px;
    min-width: 304px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}

// ------------------CONTRAST------------------

.contrast {
  .banners-list-v3 {
    background-color: $contrastBlackColor;
    border-top: 1px solid $contrastYellowColor;

    &-item {
      background-color: $contrastBlackColor;
      border: 3px solid $contrastYellowColor;
      color: $contrastYellowColor;

      &:is(a) {
        &:hover,
        &:focus {
          background-color: $contrastWhiteColor;
          border-color: $contrastWhiteColor;
          color: $contrastBlackColor;
        }
      }
    }
  }
}
